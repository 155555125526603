jQuery(document).ready(function($){

    const $window = $( window );
    const $header = $( '.header' );
    const $body = $( 'body' );

    function adjust_body_spacing () {
        $body.css( 'padding-top', `${ $header.height() }px` ) ;
    }

    adjust_body_spacing();
    $window.resize( adjust_body_spacing );

});