jQuery(document).ready(function($){

    $('.banner__slideshow__slides').each(function(){
        let slide_count = $(this).find('.banner__slideshow__slides__slide').length;
        let $prevArrow = $(this).closest( '.banner__slideshow' ).find( '.slick_replacement_buttons .prev' );
        let $nextArrow = $(this).closest( '.banner__slideshow' ).find( '.slick_replacement_buttons .next' );
        $(this).slick({
            slidesToShow: 2,
            slidesToScroll: 1,
            arrows: Boolean( $prevArrow.length ),
            prevArrow: $prevArrow,
            nextArrow: $nextArrow,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: true,
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1
                    }
                }
              ]
        });
    });


    $('.banner__carousel').carousel({
        interval: 5000,
        ride: 'carousel',
    });


    const $parallaxImages = $( "section.banner.parallax .carousel-item img" );
    if ( $parallaxImages.length ) {
        $( window ).scroll( function () {
            requestAnimationFrame( function () {
                $parallaxImages.css({
                    transform: `translateY( ${window.scrollY * 0.9}px )`
                });
            });
        });

    }

});