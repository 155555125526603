jQuery( function ( $ ) {

    const $mobile_menu = $('.mobile_menu');

    const focus_trap = focusTrap.createFocusTrap( $mobile_menu.get(0), {
        onActivate () {
            $mobile_menu.addClass('show');
            $( 'html' ).css( 'overflow-y', 'hidden' );
        },
        onDeactivate () {
            $mobile_menu.removeClass('show');
            $( 'html' ).css( 'overflow-y', 'scroll' );
        },
        initialFocus: $( 'button.mobile_menu__close' ).get(0),
    });

    
    $('.header__toggle_mobile_menu').click(function(){
        $mobile_menu.show();
        requestAnimationFrame( function () {
            focus_trap.activate();
        })
    });

    $('.mobile_menu__close').click(function(){
        focus_trap.deactivate();
        $mobile_menu.hide();
    });


    $('.mobile_menu__content__back').click(function(){
        var selected_menu = $('.menu-item a.selected');
        $('a.selected').removeClass('selected');
        $('.menu-item').hide();
        var closest_menu = selected_menu.closest('ul');
        if (closest_menu.hasClass('sub-menu')){
            console.log('still nested');
            selected_menu.closest('ul').parents('.menu-item').show();
            selected_menu.closest('ul').closest('.menu-item').find('>a').show().addClass('selected');
            selected_menu.closest('ul').show();
            selected_menu.closest('ul').find('>.menu-item').show();
        }
        else{
            console.log('at the top');
            $(this).hide();
            selected_menu.closest('ul').show();
            selected_menu.closest('ul').find('>.menu-item').show();
        }
        selected_menu.closest('ul').find('.menu-item-has-children .toggle_sub_menu').show();
    });

    $(document).on('click', '#mobile_menu__content_menu .toggle_sub_menu', function(e){
        e.preventDefault();
        $('#mobile_menu__content_menu .menu-item a ').removeClass('selected');
        $('#mobile_menu__content_menu .toggle_sub_menu').hide();
        $(this).closest('.menu-item-has-children').find('.menu-item-has-children .toggle_sub_menu').show();
        $(this).closest('.menu-item').find('>a').addClass('selected');
        $('.mobile_menu__content__back').show();

        //hide all menu items
        $('#mobile_menu__content_menu .menu-item').hide();
        $(this).parents('.menu-item').show();
        $(this).parents('.sub-menu').siblings('a').hide();
        $(this).parents('.sub-menu').show();

        //show only immediate child menu items
        $(this).closest('.menu-item').show();
        $(this).closest('.menu-item').find('.sub-menu').show();
        $(this).closest('.menu-item').find('>.sub-menu > .menu-item').show();
    });

    $( '#mobile_menu__content_menu li.menu-item-has-children > a' ).after('<button title="toggle sub menu" class="toggle_sub_menu"><span class="fas fa-caret-right toggle_sub_menu__inner"></span><span class="sr-only">Toggle sub menu</span></button>');

});

( function($) {
	function visible(element) {
		return $.expr.filters.visible(element) && !$(element).parents().addBack().filter(function() {
			return $.css(this, 'visibility') === 'hidden';
		}).length;
	}

	function focusable(element, isTabIndexNotNaN) {
		var map, mapName, img, nodeName = element.nodeName.toLowerCase();
		if ('area' === nodeName) {
			map = element.parentNode;
			mapName = map.name;
			if (!element.href || !mapName || map.nodeName.toLowerCase() !== 'map') {
				return false;
			}
			img = $('img[usemap=#' + mapName + ']')[0];
			return !!img && visible(img);
		}
		return (/input|select|textarea|button|object/.test(nodeName) ?
			!element.disabled :
			'a' === nodeName ?
				element.href || isTabIndexNotNaN :
				isTabIndexNotNaN) &&
			// the element and all of its ancestors must be visible
			visible(element);
	}

	$.extend($.expr[':'], {
		focusable: function(element) {
			return focusable(element, !isNaN($.attr(element, 'tabindex')));
		}
	});
})( jQuery );