(function ($) {
    $(function () {
      if (!$('section.blog').length) {
        return;
      }
  
      let $search_form = $('.blog-search-form');
      let $category_select = $('#category-select');
      let $load_more = $('button.load-more');
      let $articles = $('.articles');
  
      $category_select.change(function () {
        $search_form.attr('action', $(':selected', this).data('action'));
        setTimeout(function () {
          $('#blog-search-submission').click();
        }, 1);
      });
  
      $load_more.click(function () {
        $load_more.addClass('active').attr('disabled', 'disabled');
        ThemeJS.Functions.send_ajax_request({
          data: {
            action: 'load_more_posts',
            query: $load_more.data(),
          },
          success: function (response) {
            response.articles.forEach(function (value, index) {
              let $article = $(value);
              $article.css('transition-delay', `${150 * index}ms`);
              $article.addClass('ajax-loaded');
              $articles.append($article);
            });
            requestAnimationFrame(function () {
              $articles.children().removeClass('ajax-loaded');
            });
            if (response.more) {
              $load_more.data('paged', parseInt($load_more.data('paged')) + 1);
              $load_more.removeClass('active').removeAttr('disabled');
            } else {
              $load_more.remove();
            }
          },
        });
      });
    });
  })(jQuery);